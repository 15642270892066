import { createWebHistory, createRouter, RouteRecordRaw } from "vue-router";
import { authGuard } from "@auth0/auth0-vue";
import Home from "@/views/Home.vue";
import Contract from "@/views/Contract.vue";
import NotFound from "@/views/NotFound.vue";

const routes: RouteRecordRaw[] = [
    {
        path: "/",
        name: "Home",
        component: Home,
    },
    {
        path: "/vertrag",
        name: "Contract",
        component: Contract,
        beforeEnter: authGuard,
    },
    {
        path: "/:catchAll(.*)",
        name: "NotFound",
        component: NotFound,
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;
