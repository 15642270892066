
import { defineComponent } from "vue";
import BaseLayout from "./components/BaseLayout.vue";
import UserStatus from "./components/UserStatus.vue";

export default defineComponent({
    name: "App",
    components: {
        BaseLayout,
        UserStatus,
    },
});
