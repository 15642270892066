import { useAuth0 } from "@auth0/auth0-vue";

export function useAjax() {
    const { getAccessTokenSilently } = useAuth0();

    return {
        executeGet: async (url: string) => {
            const token = await getAccessTokenSilently();
            return await fetch(url, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json;charset=UTF-8",
                },
            });
        },
    };
}
