<template>
    <div class="user">
        <template v-if="user">
            <a href="https://myenergykey.de/overview" title="myEnergyKey-Konto verwalten" target="_blank" class="user__action">
                <svg class="user__icon">
                    <use xlink:href="#user"></use>
                </svg>
            </a>
            <button type="button" @click="logout" class="user__action" title="Abmelden">
                <span class="user__label">Abmelden</span>
            </button>
        </template>

        <template v-else>
            <router-link :to="{ name: 'Contract' }" custom v-slot="{ navigate }">
                <button type="button" @click="navigate" @keypress.enter="navigate" class="user__action" title="Anmelden">
                    <span class="user__label">Anmelden</span>
                </button>
            </router-link>
        </template>
    </div>
</template>

<script>
import { useAuth0 } from "@auth0/auth0-vue";
import router from "@/router";

export default {
    name: "UserStatus",
    setup() {
        const homeUrl = new URL(router.resolve({ name: "Home" }).href, window.location.href).href;
        const { logout, user } = useAuth0();

        return {
            logout: () => {
                logout({ returnTo: homeUrl });
            },
            user,
        };
    },
};
</script>
