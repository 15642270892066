<template>
    <slot name="header">
        <p>I'm the header</p>
    </slot>

    <slot name="content">
        <p>I'm the content</p>
    </slot>

    <slot name="footer">
        <p>I'm the footer</p>
    </slot>

    <slot name="svg">
        <p>SVG here</p>
    </slot>
</template>

<script>
export default {
    name: "BaseLayout",
};
</script>
