
import { defineComponent } from "vue";
import ContractList from "@/components/contract-list/ContractList.vue";

export default defineComponent({
    name: "ContractComponent",
    components: {
        ContractList,
    },
});
