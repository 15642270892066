
import { defineComponent } from "vue";
import { useAuth0 } from "@auth0/auth0-vue";

export default defineComponent({
    name: "HomeComponent",
    setup() {
        const { user } = useAuth0();

        return {
            user,
        };
    },
});
