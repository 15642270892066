
import { useAjax } from "@/composables/ajax-helper";
import { ContractResponse } from "@/components/contract-list/typings/ContractResponse";

export default {
    name: "ContractList",
    async setup() {
        const { executeGet } = useAjax();

        let contractData: ContractResponse | null = null;

        let hasLoadingError: boolean = false;

        try {
            const response = await executeGet(`${process.env.VUE_APP_API_BASE_URL}/api/GetContract`);
            contractData = (await response?.json()) as ContractResponse;
        } catch (e) {
            hasLoadingError = true;
        }

        return {
            hasLoadingError,
            contractData,
            getContractStatusDescription: (status: String) => {
                if (status === "Vertrag aktiv") {
                    return "Wir warten auf eine Rückmeldung des Umwelt&shy;bundes&shy;amtes. Der Prozess kann einige Wochen in Anspruch nehmen. Sie werden direkt im Anschluss kontaktiert.";
                } else if (status === "Vertrag beendet") {
                    return "Sie werden bald eine Auszahlung erhalten.";
                } else if (status === "Vertrag gekündigt") {
                    return "Leider haben wir eine negative Antwort des Umwelt&shy;bundes&shy;amtes erhalten. Wir können Ihre Prämie daher nicht auszahlen. Die Details erhalten Sie per E-Mail.";
                } else if (status === "Vertrag widerrufen") {
                    return "Sie haben den Vertrag widerrufen.";
                }

                return "";
            },
            isSuccessStatus: (status: String) => {
                return status === "Vertrag beendet";
            },
            isErrorStatus: (status: String) => {
                return status === "Vertrag gekündigt" || status === "Vertrag widerrufen";
            },
        };
    },
};
